import(/* webpackPrefetch: true */ "./icons");
import(/* webpackPrefetch: true */ "./animations");
import(/* webpackPrefetch: true */ "./fonts");
import(/* webpackPrefetch: true */ "bootstrap/dist/js/bootstrap.min");

const mapsUrl = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2549.9316663200048!2d19.141373615965!3d50.27453450790546!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716dac75754858d%3A0x7f401c4355770291!2sAdwokat%2C%20Radca%20prawny%2C%20Kancelaria%20Prawna%20Sosnowiec!5e0!3m2!1sen!2spl!4v1603535494430!5m2!1sen!2spl";

(async ($) => {
    $(document).ready(() => {
        // smooth scroll to menu links
        $(".scroll").click((event) => {
            event.preventDefault();
            $("html,body").animate({scrollTop: $(event.target.hash).offset().top}, 1000);
        });
    });

    const googleMap = $("iframe#google_maps_iframe");
    let googleLoaded = false;

    $(window).scroll(() => {
        let hT = googleMap.offset().top;
        let hH = googleMap.outerHeight();
        let wH = $(window).height();
        let wS = $(window).scrollTop();
        if (wS > (hT + (hH / 2) - wH) && googleLoaded === false) {
            googleLoaded = true;
            $("iframe#google_maps_iframe").attr("src", mapsUrl);
        }
    });
})(jQuery);
